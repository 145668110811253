* {
    font-family: 'Outfit', sans-serif;
    font-weight: 400;
}



.internal-container {
    width: 60%;
}

.btn-header-action {
    margin-top: 1.325rem;
    margin-right: 1.375rem;
    border-radius: 2rem !important;
}

.btn-br-dark {
    background-color: rgb(47, 53, 58) !important;
}

.header-bar {
    width: 90%;
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.logo {
    padding-top: 1.825rem;
}

.logo-img-internal {
    width: 10.4375rem;
}

.img-container {
    position: relative;
}

.vcard-avatar-img {
    margin-top: 0.6rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
}

.profile-info-title-text {
    font-weight: 600;
}

.profile-info-position-text {
    color: #878A8D;
}



.profile-section {
    padding: 0 2.4rem;
}

.profile-contacts {

}

.qr-code-wrapper {
    display: flex;
    justify-content: center;
}

.employee-info-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.arrow-right {
    width: 1.25rem;
    height: 1.25rem;
}

.btn-arrow-right {
    border-radius: 1.25rem !important;
}


.contact-ico {

}

.social-media {
    width: 100%;
}

.subtitle {
    font-size: .75rem;
    font-weight: 500;
    color: #FD8D75;
}

.section-text {
    color: #186ADE;
}

.company-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.company-info-section {    
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.website {
    width: 100%;
}

.qr-code {
    width: 100%;
    max-width: 22rem;
    margin-top: 4rem;
}


.qr-code-card-internal {
    width: 100%;
    height: 20rem;
    box-shadow: 0 0 1.25rem rgba($color: #000000, $alpha: 0.08);
    border-radius: 0.8rem;
}

.qr-code-container {
    display: flex;
    justify-content: center;
}

.qr-code-img {
    width: 7.3rem;
    height: 7.3rem;
}

.subsection-i {
    padding: 0.5rem 0.8rem 0.5rem 0;
    margin-top: 1.5rem;
    width: 48%;
}
.btn-external-link {
    border-radius: 100% !important;
    height: 2.56rem;
}

.btn-download {
    border-radius: 100% !important;
    height: 2.56rem;
}

// S8 styles

.text-gradient {
    background-image: linear-gradient(to left, #114BD6, #8ACBEC);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.btn-external-link-s8 {
    border-radius: 100% !important;
    height: 2.56rem;
    background-color: white !important;
}

.btn-cv-s8 {
    background-color: white !important;
    color: black !important;
}

.switch-bar[aria-disabled="true"] {
    background-color: #000000;
}

.profile-top-offset {
    margin-top: 1rem;
}

.clickable-data {
    padding: 0.1rem;
}

.clickable-data:hover {
    opacity: 0.5;
    cursor: pointer;
}


// Desktop
@media only screen and (min-width: 700px) {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
    }

    .profile-contacts-section {
        display: flex;
        flex-wrap:wrap;
        column-gap: 5rem;
    }

    .profile-container {
        max-width: 65rem;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .profile-top-offset {
        margin-top: 3rem;
    }

    .subsection-i {
        margin-top: 2rem;
        min-width: 20%;
        max-width: 20%;
    }

    .social-media {
        width: 35%;
    }

    .offset {
        padding-left: 2.7rem;
    }

    .entity {
        max-width: 30%;
    }

    .website {
        width: auto;
    }

}