.circle-status {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    border: .1rem solid white;
    border-radius: 50%;
    z-index: 2;
}


@media only screen and (min-width: 1150px) {
  .directory-circle-status {
    width: 1.7rem;
    height: 1.7rem;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}