
/* Common */
*, body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .directory {
    min-height: 100vh;
    width: 100%;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .btn {
    background:none;
    border: none;
  }

  /* Header */

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    margin: 0 2.5rem;
  }

  .search-form {
    display: flex;
    align-items: center;
  }

  .sort-icon {
    width: 2rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .sort-icon-img-s8 {
    filter: invert(100%);
  }

  .logo-img {

  }

  .logo-img-s8 {

  }

  .header-bar {
    max-width: 78.125rem;
    min-width: 42rem;
    width: 100%;
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


  .directory-search {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
  }

  .directory-search-offset {
    margin-top: 5rem;
  }

  .search-form {
    width: 90%;
    min-width: 16rem;
    max-width: 25rem;
  }

  .search {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  .search-field {
    width: 100%;
    background-color: #F3F3F4;
    border: none;
    padding: 0.7rem 1.5rem;
    border-radius: 2rem;
  }

  .search-field-s8 {
    color: white;
  }

  .search .search-filter-icon {
    position: absolute;
    top: 0.4rem;
    left: 0.7rem;
  }

  .search .search-icon {
    position: absolute;
    top: 0.6rem;
    right: 0.7rem;
  }

  .pagination {

  }

  .btn-br {
    background-color: none;
  }

  .btn-br-dark {
    color: white;
  }

  /* Employee list */

  .employee-list {
    margin-top: 1.5rem;
    padding: 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pagination {

  }

  .next-twenty {
    color: black !important;
    background-color: white !important;
  }

  .next-twenty-dark {
    background-color: none;
    color: white;
  }

  .employee-list-item {
    max-width: 70rem;
    min-width: 67rem;
    width: 100%;
    padding: 2rem;
    background-color: #F3F3F4;
    border-radius: 7rem;
    display: flex;
    align-content: stretch;
    align-items: center;
    margin-bottom: 1rem;
  }

  .employee-list-item:hover {
    background-color: rgb(220, 220, 220);
  }

  .list-item-avatar {
    width: 9rem !important;
    height: 9rem !important;
    border-radius: 50% !important;
  }

  .list-item-description {
    width: 80%;
    margin-left: 3rem;
    margin-top: 0.5rem;
  }

  .list-item-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin-top: 0.5rem;
  }

  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
  }

  .list-item-name {
    text-align: start;
    font-size: 1.1rem;
    color: #FD8D75;
  }

  .item-title {
    font-weight: 600;
    font-size: 1.2rem;
  }

  .item-subtitle {
    font-weight: 600;
    margin-top: 1rem;
  }

  .list-item-data {
    width: 30%;
  }

  .list-item-info-data {
    text-align: start;
  }

  .search-form-options {
    width: 20rem;
  }

  .text-field {
    padding: 0 0.7rem;
    width: 13rem;
    border-radius: 3rem;
  }

  .avatar-img {
    width: 9.5rem !important;
    height: 9.5rem !important;
  }

  .avatar span {
    font-size: 4rem;
  }

  .switch-font-sm {
    font-size: 0.65rem;
  }

  // Mobile version

  .header-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .employee-list-mobile {
    margin-top: 2rem;
  }

  .employee-list-item-mobile {
      width: 100%;
      min-width: 20rem;
      padding: 0.65rem;
      background-color: rgb(246, 246, 246);
      border-radius: 5rem;
      margin-bottom: 1rem;
  }

  .list-item-avatar-mobile {
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 50%;
    background-color: rgb(220, 220, 220);
  }

  .toggle-item{
    font-size: 0.7rem;
  }


  .item-title-mobile {
    font-weight: 600;
    font-size: 1.1rem;
  }

  .item-text-mobile {
    font-weight: 600;
    font-size: 0.85rem;
  }

  // Loaders

  .loader-employee {
    margin-top: 10rem;
  }

  .message-employee {
    margin-top: 10rem;
  }

  .w-90 {
    width: 90%;
  }

  @media only screen and (max-width: 1150px) and (min-width: 600px){
    .list-item-avatar {
      width: 5.5rem !important;
      height: 5.5rem !important;
      border-radius: 50% !important;
    }

    .avatar-img {
      width: 5.5rem !important;
      height: 5.5rem !important;
    }

    .employee-list-item {
      max-width: 63.5rem;
      min-width: 48rem;
      width: 100%;
      padding: 1rem;
    }

    .avatar span {
      font-size: 2.5rem;
    }

    .employee-list {
      font-size: 0.7rem
    }

    .presence-icon-directory {
      position: absolute;
      bottom: 0.5rem;
      left: 4rem;
    }

    .list-item-description {
      width: 90%;
      margin-left: 2rem;
    }

    .list-item-data {
      width: 33%;
    }

  }

  @media only screen and (max-width: 800px) {
    .avatar span {
      font-size: 1.5rem;
    }

    .directory-search-offset {
      margin-top: 0;
    }

    .presence-icon-directory {
      position: absolute;
      bottom: 0.2rem;
      left: 2.8rem;
    }
  }
