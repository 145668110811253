.qr-code-card-synpulse {
    width: 100%;
    box-shadow: 0 0 1.25rem rgba($color: #000000, $alpha: 0.08);
    background-color: #fff;
    color:black;
    border-radius: 0.8rem;
    padding: 1.4rem;
}

.qr-code-card-synpulse8 {
    width: 100%;
    box-shadow: 0 0 1.25rem rgba($color: #fff, $alpha: 0.08);
    border-radius: 0.8rem;
    background-color: rgb(18, 26, 33);
    color: #fff;
    padding: 1.4rem;
}

.qr-code-container {
    display: flex;
    justify-content: center;
}

.btn-download-synpulse {
    border-radius: 100% !important;
    height: 2.56rem;
    background-color: #000000;
}

.btn-download-synpulse8 {
    border-radius: 100% !important;
    height: 2.56rem;
    background-color: #fff;
}

.icon-synpulse8 {
    filter: invert(100%);
}