// .btn {
//     background:none;
//     border: none;
// }

a:hover {
    color: #E86427;
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.control-panel {
    margin-top: 1rem;
    width: 20rem;
    display: flex;
    justify-content: space-between;
}

.btn-view {
    width: 5rem;
    background-color: black;
    color: white;    
    padding: 0.3rem 0;        
    margin-bottom: 0.2rem;
    border-radius: 2rem;
}

.spacer {
    width: 100%;
    padding: 2.1rem;
}