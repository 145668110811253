.advance-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.advance-search-container-s8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121A21 !important;
}

.white-text {
    color: white;
}

.input-container {
    width: 30%;
    padding: 0.7rem;
    display: flex;
    flex-direction: column;
}

.input-title {
    font-weight: bold;
    padding-bottom: 0.6rem;
}

.input-title-s8 {
    font-weight: bold;
    padding-bottom: 0.6rem;
    color: white;
}

.input {
    width: 100%;
    border-color: #E7E8E8;
    border-radius: 0.2rem;
    height: 2rem;
    color: black;
}

.input fieldset {
    border-color: #E7E8E8;
    border-radius: 0.2rem;
}

.input-s8 {
    background-color: #121A21;
    width: 100%;
    border-radius: 0.2rem;
    color: white;
    height: 2rem;
}

.input-s8 fieldset {
    border-color: #6B7280;
    border-radius: 0.2rem;
}

.input-s8 svg {
    color: #6B7280;
}

.input-dropdown {
    width: 100%;
}

.filter-form {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
}

.filter-form-s8 {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container-s8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121A21;
}

.filters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.remove-filters-btn {
    text-align: center;
    padding-bottom: 1rem;
}

.submit-filters-btn {
    background-color: #121A21;
    color: white;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 0.4rem;
}

.submit-filters-btn-s8 {
    background-color: white;
    color: #121A21;
    text-align: center;
    font-weight: bold;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-radius: 0.4rem;
}

.bottom-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.5rem;
}

.MuiButtonBase-root {
    font-family: "Outfit", sans-serif;
}

@media (max-width: 768px) {
    .input-container {
      width: 100%;
    }
}