/* ScrollToTopButton.css */
.scroll-to-top-btn {
    width: 3rem;
    height: 3rem;
    display: none;
    position: fixed;
    bottom: 1.2rem;
    right: 1.2rem;
    background-color: #fff;
    color: #000;
    border:none;
    border-radius: 50%;
    box-shadow: 0 0 .3rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }

  .scroll-to-top-btn.visible {
    display: block;
    opacity: 1;
  }
