//Lead generation form styles


.lead-gen-form-container {
    position: fixed;
    width: 15%;
    max-width: 20rem;
    right: 1rem;
    box-shadow: 0 0 0.1rem;
    border-radius: 1rem;
    min-width: 15rem;
    bottom: 0rem;
}

.s8-container {
    box-shadow: 0 0 1.25rem rgba(255, 255, 255, 0.08);
}

.lead-gen-form-title {
    color: white;
    background-color: black;
    padding: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    text-align: center;
}

.lead-gen-form-title-s8 {
    background-color: #121A21;
}
